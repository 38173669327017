export default {
  SPOT_LIST: "Spot list",
  ADD_SPOT: "Add spot",
  SPOT_ADDED: "Spot added",
  EDIT_SPOT: "Update spot",
  SPOT_UPDATED: "Spot updated",
  DELETE_THIS_SPOT: "Delete this spot ?",
  SPOT_DELETED: "Spot deleted",
  SPOT_GENERAL_INFORMATION: "General information",
  SPOT_SPECIFIC_INFORMATION: "Specific informations",
  SPOT_CAPACITY: "Capacity",
  SPOT_PET_CAPACITY: "Animals",
  SPOT_PETS_CAPACITY: "Number of animals",
  SPOT_CHILDREN_CAPACITY: "Number of children",
  SPOT_ADULT_CAPACITY: "Number of adults",
  SPOT_NAME: "Spot name",
  SPOT_TYPE: "Spot type",
  SPOT_EQUIPMENTS: "Equipments",
  SPOT_SHEET: "Fiche camping",
  SPOT_LENGTH: "Location length",
  SPOT_SCHEDULE: "Schedule",
  SPOT_ARRIVAL_TIME: "Arrival time",
  SPOT_DEPARTURE_TIME: "Departure time",
  SPOT_INCLUDED_EQUIPMENT: "Included equipment",
  SPOT_WITH_SERVICES: "With Services",
  SPOT_GALLERY: "Gallery",
  SPOT_MAIN_IMAGE: "Main image",
  SPOT_OTHER_IMAGE: "Other images",
  SPOT_STATUS_AVAILABLE: "Available",
  SPOT_STATUS_NOT_AVAILABLE: "Not available",
  CLEAN_CONDITION: "Clean condition",
  SPOT_CONDITION_CLEAN: "Clean",
  SPOT_CONDITION_DIRTY: "Dirty",
  SPOT_LENGTH_FEET: "feet",
  SAME_SPOTS: 'Un ou plusieurs emplacements ont déjà été enregistrés avec le nom {name}. S\'agit-il du même emplacement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
};
