export default {
  SPOT_TYPE_LIST: "SpotType list",
  ADD_SPOT_TYPE: "Add spotType",
  SPOT_TYPE_ADDED: "SpotType added",
  EDIT_SPOT_TYPE: "Update spotType",
  SPOT_TYPE_UPDATED: "SpotType updated",
  DELETE_THIS_SPOT_TYPE: "Delete this spotType ?",
  SPOT_TYPE_DELETED: "SpotType deleted",

  SPOT_TYPE_NAME: "Spot type name",

  SERVICES: "Services",
  ELECTRICITY: "Electricity",
  OTHER_EQUIPMENT: "Other Equipment",

  WITH_SERVICE: "With Services",
  WITHOUT_SERVICE: "Without Services",

  "20_AMPS": "20 Amp Electricity",
  "30_AMPS": "30 Amp Electricity",
  "50_AMPS": "50 Amp Electricity",

  SAME_SPOT_TYPE_TYPES: 'Un ou plusieurs types d\'emplacements ont déjà été enregistrés avec le nom {name}. S\'agit-il du même type d\'emplacement ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
};
